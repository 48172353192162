import MKTypography from "components/MKTypography";

// react-router components
import { Link } from "react-router-dom";

// eslint-disable-next-line react/prop-types
function TcLink({ children }) {
  return (
    <MKTypography variant="caption" fontWeight="regular" sx={{ textDecoration: "underline" }}>
      <MKTypography
        component={Link}
        to="https://www.dttd.io/terms-and-conditions"
        target="_blank"
        rel="noreferrer"
        variant="caption"
        fontWeight="regular"
      >
        {children}
      </MKTypography>
    </MKTypography>
  );
}

export default TcLink;
