/* eslint-disable react/prop-types */

import { useContext } from "react";
import { useTranslation } from "react-i18next";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Link from "@material-ui/core/Link";

import { MainContext } from "contexts/MainContext";

// react-router-dom components
import { Link } from "react-router-dom";
import TrackedExtLink from "dttd/components/TrackedExtLink";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import colors from "assets/theme/base/colors";

export const FooterMobile = ({ content, changeLanguageHandler }) => {
  const mainContext = useContext(MainContext);
  // eslint-disable-next-line react/prop-types
  const { socials, copyright } = content;

  const { t } = useTranslation();

  const socialBgStyle = {
    height: "24px",
    width: "24px",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    display: "inline-block",
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} mt={1} alignItems="center">
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={6} sm={6} alignItems="flex-end">
                <MKBox display="flex" alignItems="center" color="dttdFooterText">
                  {socials.map(({ icon, link }, key) => (
                    <MKTypography
                      key={link}
                      component={TrackedExtLink}
                      to={link}
                      target="_blank"
                      rel="noreferrer"
                      variant="h5"
                      color="dttdFooterText"
                      opacity={0.8}
                      mr={key === socials.length - 1 ? 0 : 2.5}
                      sx={socialBgStyle}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <MKBox sx={{ m: "2px" }}>{icon}</MKBox>
                    </MKTypography>
                  ))}
                </MKBox>
              </Grid>
              <Grid item xs={6} sm={6} alignItems="center">
                <Grid container justifyContent="flex-end">
                  <MKBox display="flex" alignItems="center" color="dttdFooterText">
                    <MKTypography
                      component={Link}
                      to="#"
                      rel="noreferrer"
                      variant="button"
                      color="dttdFooterText"
                      mx={1}
                    >
                      <Link onClick={() => changeLanguageHandler("tc")}>
                        <MKTypography
                          color="dttdFooterText"
                          size="sm"
                          fontWeight={mainContext.language === "tc" ? "medium" : "light"}
                          sx={{
                            fontSize: "0.75rem",
                            textDecoration: mainContext.language === "tc" ? "none" : "underline",
                          }}
                        >
                          繁
                        </MKTypography>
                      </Link>
                    </MKTypography>
                    <MKTypography color="dttdFooterText">|</MKTypography>
                    <MKTypography
                      component={Link}
                      to="#"
                      rel="noreferrer"
                      variant="button"
                      color="dttdFooterText"
                      mx={1}
                    >
                      <Link onClick={() => changeLanguageHandler("en")}>
                        <MKTypography
                          color="dttdFooterText"
                          size="sm"
                          fontWeight={mainContext.language === "en" ? "medium" : "light"}
                          sx={{
                            fontSize: "0.75rem",
                            textDecoration: mainContext.language === "en" ? "none" : "underline",
                          }}
                        >
                          ENG
                        </MKTypography>
                      </Link>
                    </MKTypography>
                    {/* <MKTypography color="white">|</MKTypography>
                    <MKTypography
                      component={Link}
                      to="#"
                      rel="noreferrer"
                      variant="button"
                      color="white"
                      mx={1}
                    >
                      <Link onClick={() => changeLanguageHandler("sc")}>
                        <MKTypography
                          color="white"
                          size="sm"
                          fontWeight={mainContext.language === "sc" ? "medium" : "light"}
                          sx={{
                            fontSize: "0.75rem",
                            textDecoration: mainContext.language === "sc" ? "none" : "underline",
                          }}
                        >
                          简
                        </MKTypography>
                      </Link>
                    </MKTypography> */}
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <MKBox display="flex" alignItems="center" color="dttdFooterText">
            <MKTypography variant="h4" color="dttdFooterText" mx={2}>
              {copyright}
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid item xs={12}>
          <MKBox display="flex" alignItems="center" color="dttdFooterText">
            <MKTypography variant="h4" color="dttdFooterText" mx={2}>
              <MKTypography
                variant="button"
                fontWeight="regular"
                color={colors.dttdFooterText}
                sx={{ textDecoration: "underline" }}
              >
                <MKTypography
                  component={TrackedExtLink}
                  to="https://www.dttd.io/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="medium"
                  color={colors.dttdFooterText}
                >
                  {t("footer.terms&conditions")}
                </MKTypography>
              </MKTypography>
            </MKTypography>
            <MKTypography variant="h4" color="dttdFooterText" mx={2}>
              <MKTypography
                variant="button"
                fontWeight="regular"
                color={colors.dttdFooterText}
                sx={{ textDecoration: "underline" }}
              >
                <MKTypography
                  component={TrackedExtLink}
                  to="https://www.dttd.io/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="medium"
                  color={colors.dttdFooterText}
                >
                  {t("footer.privacypolicy")}
                </MKTypography>
              </MKTypography>
            </MKTypography>
          </MKBox>
        </Grid>
      </Grid>
    </Container>
  );
};
